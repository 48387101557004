import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w09KG08Karten = () => (
  <Layout>
    <SEO title="w09KG08Karten" />

    <h1 id="ueberschrift">Kreatives Gestalten</h1>
    <h2>(Gruß-)Karten basteln für Groß und Klein &emsp;  ©&nbsp;1998</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w09KG08Karten&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Tischkärtchen, Grußkarte, Kon­do­lenz­kar­te, etc.;<br />
        aus Tonpapier, Geschenkpapier, Servietten, etc.;<br />
        verziert mit Glitter, Trockenblumen, Herbstlaub, etc.
      </p>
      <p>
        Gemeinsam mit anderen können Sie Ihr kreatives Talent suchen und entdecken, indem Sie 
        unter Anleitung verschiedene Karten aus bestimmten Grundmaterialien basteln.
        Genießen Sie die Zeit beim geselligen Experimentieren, Fluchen, Beneiden und Freuen.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w09KG08Karten
